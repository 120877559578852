








































import { Component, Vue } from 'vue-property-decorator'
import { BSpinner } from 'bootstrap-vue'
import { cart } from '@/store/cart/cartModule'

@Component({ components: { BSpinner } })
export default class ObedRuWait extends Vue {
  /**
   * Флаг для отображения текста о сканировании кода обед ру
   */
  get isShowedObedRuCodeText(): boolean {
    return cart.isShowedObedRuCodeText
  }
}
